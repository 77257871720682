#geocoder {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.mapboxgl-ctrl-geocoder {
  width: 100%;
  border-radius: 15px;
  font-size: 16px;
}

.mapboxgl-ctrl-geocoder input[type='text'] {
  font-size: 16px;
}
